import React from 'react';
import { useState} from 'react';
import { Container, Row, Col, Button, Card} from 'react-bootstrap';
import {initTheme} from '../utils/theme'
import {TbEdit, TbInfoCircle} from 'react-icons/tb';
import {PiChartLineUpBold} from 'react-icons/pi';
import { BsFillBuildingsFill } from "react-icons/bs";
import { AiOutlineSafety } from "react-icons/ai";
import { BiWorld } from "react-icons/bi";
import {FaBookAtlas } from 'react-icons/fa6';
import { GrDocumentTest } from "react-icons/gr"
import logo from '../assets/logo.png';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import FormSelect from 'react-bootstrap/esm/FormSelect';
import i18n from '../language/i18';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import './Landing.css';
import '../components/header/Header.css'
import Graphline from '../components/todaysignals/Graphline';
import {landingExampleData} from"../utils/landingExampleData"
import Footer from '../components/Footer';
import SignInButton from '../components/landing/SingInButton';
import { useDispatch, useSelector } from 'react-redux';
import { setSettings } from '../redux/settingsReducer';
import { Player } from '@lottiefiles/react-lottie-player';
import { positionColor } from '../utils/colors';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const Landing = () => {
  // code to get settings values from redux
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.settings);
  const setSettings1 = (res)=>{dispatch(setSettings(res))}
  //for navigation
  const navigate = useNavigate();
 // code for initializing the theme
  initTheme()
  const [language, setLanguage] = useState(settings.language);
  // hook for translation
  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(language);
    setSettings1({...settings, language: language})
  }, [language]);
  // google analytics
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

 
  
    return (
        <div >
        {/*  header */}
        <Navbar className="bg-dark pb-1 pt-1" sticky='top'>

          <Container className='mw-100'>

          
          <Nav.Link href='/' className= "d-flex">
          <img className= "mx-1" width="45" height="45" src={logo} loading="lazy"></img>
          <div className='fw-bold d-none d-lg-flex align-items-center text-light h3 m-0'>AtomicStonks</div>
          </Nav.Link>
            <div  className='d-flex me-2 col justify-content-end' >
                <a className='d-none d-lg-block p-2 pe-4 text-light text-decoration-none' href="/aboutus">{t('header.aboutus')}</a>
                <FormSelect bsPrefix='language-select' onChange={(e)=>{setLanguage(e.target.value)}} size="sm" className="bg-dark text-light me-2 border-0" defaultValue={language}>
                <option value="en">En</option>
                <option value="es">Es</option>
                </FormSelect>
                <Button className='ms-3 logInButton' onClick={()=>{navigate('/todaysignals')}}>
                  {t('landing.login')}
                </Button>
            </div>
            
          </Container>
          </Navbar>
     {/*  End of header */}
     {/*  Start of never miss */}
      <div className='firstBackGround'>
        <Container>
          <Row className="pt-5 mt-lg-5 mb-lg-5 pb-lg-3">
            <Col xl={8} className='align-items-center'>
             <div className="titulo mb-4 mb-lg-5 text-start">
                  <span className="resaltarTitulo">{t('landing.title')}</span>
                  <span>{t('landing.title2')}</span>
            </div>
 {/*           <div className='subtitle text-start'>
              <span className='subtitleAtention2' >{t('landing.subtitle3')}</span>
              <span>{t('landing.subtitle31')}</span>
            </div>
            <div className='subtitle text-start'>
              <span className='subtitleAtention2 ' >{t('landing.subtitle4')}</span>
              <span>{t('landing.subtitle41')}</span>
            </div>
            <div className='subtitle text-start'>
              <span className='subtitleAtention2' >{t('landing.subtitle5')}</span>
              <span>{t('landing.subtitle51')}</span>
            </div> */}
            <div className='subtitle text-start'>
              <span className='subtitleAtention2' >{t('landing.subtitle6')}</span>
              <span>{t('landing.subtitle61')}</span>
              <span className='subtitleAtention2' >{t('landing.subtitle62')}</span>
              <span>{t('landing.subtitle63')}</span>
            </div>

            
            </Col>
            <Col xl={4} >
              <Player
                src='https://atomicstonks-public.s3.eu-north-1.amazonaws.com/landing/LandingAnimation3.json'
                loop
                autoplay
                style={{width:'275px'}}
              />
            </Col>
          </Row>
          <Row className="mt-1 pb-3">
            <Col className='text-center'>
            <SignInButton></SignInButton>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Start of signal explanation */}
      <div className='secondBackGround'>
        <Container className='pb-3 pt-0'>
          
          <Row className="pt-5 pt-lg-0">
          <Col xl={4} className='d-none d-lg-block'>
            <Player
                  src='https://atomicstonks-public.s3.eu-north-1.amazonaws.com/landing/LandingAnimationSearch.json'
                  loop
                  autoplay
                  speed={0.2}
                  style={{width:'350px'}}
                />
            </Col>
          <Col xl={8} className='d-flex align-items-center'>
            <div className="titulo mb-2 text-start mt-3">
                  <span>{t('landing.howitworks1')}</span>
                  <span className="resaltarTitulo">{t('landing.howitworks2')}</span>
            </div>
            
            </Col>
            
          </Row>
          <Row >
            <Col className='mb-4 mt-3 '>
              <div className='subtitle text-start'>
                <span >{t('landing.subtitle1')}</span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className='d-inline-flex mb-3'>
              <div className='subtitle text-start w-100'>
                  <span>{t('landing.signalExpIntro3')}</span>
                  <div className='text-white text-center fw-bold px-2 rounded d-inline-flex' style={{'backgroundColor':positionColor.long}}>
                      {t('data.position.long')}
                  </div>
                  <span>{t('landing.signalExpIntro4')}</span>
                  <span><Link to={'/wiki/macd_ema'}>{t('data.strategy.macd_ema')}</Link></span>
                  <span>{t('landing.signalExpIntro5')}</span>
              </div>
            </Col>
          
          </Row>
                
          <Row className="mt-5 mb-5">
            <Container className='px-2 bg-white text-dark rounded-3 glowing-shadow pt-3 pb-3 pt-lg-4 pb-lg-4'>
              <Row className=' mb-2 mx-1 d-none d-lg-flex'>
                <Col xs={2} lg={1}>
                </Col>
                      <Col xs={8} lg={10}>
                          <Container fluid >
                              <Row>
                                  <Col lg={3} className='text-start h5 px-0'>
                                  {t('tablah.company')}
                                  </Col>
                                  <Col lg={2} className='text-start h5 px-0'>
                                  {t('tablah.strategy')}
                                  </Col>
                                  <Col lg={2} className='text-start h5 px-0'>
                                  {t('tablah.position')}
                                  </Col>
                                  <Col lg={2} className='d-none d-lg-block text-start h5 px-0'>
                                  {t('tablah.lastclosing')}
                                  </Col>
                                  <Col lg={3} className='d-none d-lg-block text-start h5 px-0'>
                                  {t('tablah.sector')}
                                  </Col>
                              </Row>
                          </Container>
                      </Col>
                      <Col lg={1}>
                      </Col>
              </Row>
              <Graphline className="callAttention" id = {1} line = {landingExampleData} settings={settings}></Graphline>
            </Container>
          </Row>
           {/* Start of signal explanation */}
          <Row className="mt-3">
            <Col  className='d-inline-flex'>
              <div className='subtitle text-center w-100'>
                {t('landing.signalExp1')}
                <br></br>
                {t('landing.signalExp1_1')}
                <Button type ="Button" variant="outline-secondary"  className="btn p-1 me-2  bg-light"
                style={{ pointerEvents: 'none' }}>
                  <TbInfoCircle className='text-primary h4 m-0' ></TbInfoCircle>
                </Button>
                {t('landing.signalExp2')}
                <Button  type ="Button" variant="outline-secondary" className="btn p-1 me-2 bg-light"
                style={{ pointerEvents: 'none' }}>
                  <PiChartLineUpBold className='text-primary h4 m-0' ></PiChartLineUpBold>
                </Button>
                <br></br>
                {t('landing.signalExp3')}
                <Button type ="Button" variant="outline-secondary" className="me-2 p-1 bg-light"
                style={{ pointerEvents: 'none' }}>
                  <TbEdit className='text-primary h4 m-0' ></TbEdit>
                </Button>
              </div> 
            </Col>
          </Row>
          <Row>
            <Col className='d-inline-flex'>
              <div className='subtitle text-start w-100 mt-5'>
                {t('landing.signalExpIntro1')}
                <Button className='text-light p-0 d-inline-flex bg-dark border-2 fw-bold align-items-center border-light' href='/todaysignals' >
                      <img className= "m-0 me-1" style={{filter: 'brightness(0) invert(1)'}} width="38" height="38" src={logo} loading="lazy"></img>
                        <span className= "m-0 me-1">{t('header.todaysignals')}</span>
                </Button>
                {t('landing.signalExpIntro2')}
              </div>
            </Col>
          </Row>
          <Row className="mt-5 mb-5">
            <Col className='text-center titulo'>
              <span className='text-light'>{t('landing.signalExp4')}</span>
            </Col>
          </Row>
          <Row className="mt-5 pb-3">
            <Col className='text-center'>
              <SignInButton></SignInButton>
            </Col>
          </Row>
          </Container>
        </div >
        {/* Start of cards */}
        <div className='thirdBackGround pt-5'>
          <Container>
         
          <Row className="pt-3">
          <Container className="d-flex flex-wrap justify-content-center">

              <Card style={{ width: '18rem' }} className='border border-secondary border-4 m-2 card-animation'>
                <Card.Body className='text-center'>
                  <div className='p-3 border rounded-circle border-4 border-primary d-inline-block'>
                    <BsFillBuildingsFill size={60} className='text-dark'/>
                  </div>
                  
                  <Card.Title className='mt-4 mb-4 mb-lg-5'>{t('landing.features1')}</Card.Title>
                  <Card.Text className='text-start'>
                    {t('landing.features11')}
                  </Card.Text>
                </Card.Body>
              </Card>

              <Card style={{ width: '18rem' }} className='border border-secondary border-4 m-2 card-animation'>
                <Card.Body className='text-center'>
                  <div className='p-3 border rounded-circle border-4 border-primary d-inline-block'>
                    <AiOutlineSafety size={60} className='text-dark'/>
                  </div>
                  
                  <Card.Title className='mt-4 mb-4'>{t('landing.features2')}</Card.Title>
                  <Card.Text className='text-start'>
                    {t('landing.features22')}
                    <Button className='text-light p-1 d-inline-flex bg-dark border-0 fw-bold align-items-center' href='/wiki' >
                        <FaBookAtlas className='mt-1 mt-lg-0 mb-0 me-2 h3'/>
                        <span className= "m-0 me-1">{t('header.wiki')}</span>
                    </Button>
                    {t('landing.features23')}
                  </Card.Text>
                </Card.Body>
              </Card>

              <Card style={{ width: '18rem' }} className='border border-secondary border-4 m-2 card-animation'>
                <Card.Body className='text-center'>
                  <div className='p-3 border rounded-circle border-4 border-primary d-inline-block'>
                    <BiWorld size={60} className='text-dark'/>
                  </div>
                  
                  <Card.Title className='mt-4 mb-4'>{t('landing.features3')}</Card.Title>
                  <Card.Text className='text-start'>
                    {t('landing.features33')}
                  </Card.Text>
                </Card.Body>
              </Card>
          </Container>
          </Row>
        </Container>
      </div>

        {/* Start of backtesting explanation */}
        <div className='fouthBackGround'>
          <Container>
          <Row className=" mb-3 mt-2 mb-lg-5 mt-lg-4">
            <Col xl={8} className="d-flex pt-4 ">
            <div className="titulo text-start">
                  <div>{t('landing.backTestingTitle1')}</div>
                  <div>
                    <span className="resaltarTitulo">{t('landing.backTestingTitle2')}</span>
                  </div>
            </div>
            
            </Col>
            <Col xl={4} className='d-none d-lg-block'>
              <Player
                src='https://atomicstonks-public.s3.eu-north-1.amazonaws.com/landing/LandingProcessInformation.json'
                loop
                autoplay
                speed={0.5}
                style={{width:'200px'}}
              />
            </Col>
          </Row>
          <Row className="subtitle d-inline-flex mt-3 mb-5">
            <Col className='w-100 text-start'>
              {t('landing.backTesting1')}
              <Button className='text-light p-0 ps-1 d-inline-flex bg-dark border-0 fw-bold align-items-center mx-1' href='/ranking' >
                <GrDocumentTest className='p-1 mt-1 mt-lg-0 mb-0 me-2 h1'/>
                <span className= "m-0 me-1">{t('header.ranking')}</span>
              </Button>
              {t('landing.backTesting2')}
            </Col>
          </Row>
          <Row className="mt-3">
              <video width="100%"  muted autoPlay loop controls>
                <source src={t('landing.video')+"#t=0"} type="video/mp4"></source>
              </video>
          </Row>
          {/* <Row className="mt-5 mb-5">
            <TodaysignalsFilter selection={null}></TodaysignalsFilter>
          </Row> */}
          <Row className="mt-5 pb-5 mb-5">
            <Col className='text-center'>
              <SignInButton></SignInButton>
            </Col>
          </Row>
          </Container>
        </div> 
        
      <Footer className="mt-5"></Footer>
      </div>
    );
  };

export default Landing;