import { wikiEs } from "./wikiEs";
import { wikiHeadersEs } from "./wikiHeadersEs";
export const translationEs = 
{
title:'hola',
tablah : {
    profit:'Rent.',
    day_period:'Últimos',
    lastclosing : 'Últ. Cierre',
    exchange : 'Mercado',
    indexes:'Índice',
    position : 'Posición',
    volume : 'Volumen',
    country : 'País',
    strategy : 'Estrategia',
    company: 'Empresa',
    graph:'Gráfico',
    sector:'Sector',
    industry:'Industria',
    website:'Web',
    nodata: 'No hay más datos disponibles para la selección',
    need_premium_to_see:'Con la suscripción Premium habrías visto esta señal',
    need_pro_to_see:'Con la suscripción Pro habrías visto esta señal'
},
tradeView:{
    notSupported:'Lo sentimos, este mercado actualmente no esta soportado por TradingView widget.',
},
kofiButton:{
    supportUs:'Si AtomicStonks te parece útil, ¡Apóyanos con una cerveza!',
},
update_user:'Actualizando usuario, serás redirigido automáticamnete',
graphinfo:{
    date:'Fecha',
    dateOpen:'Op. Abierta',
    dateEntry:'Fecha Entrada',
    dateExit:'Fecha Salida',
    price:'Precios',
    exitReason:'Motivo Salida',
    entry:'Entrada',
    exit:'Salida',
    opTable:{
        'cross_inverse_band': 'El precio cruzó la banda opuesta y volvió dentro de las bandas',
        'cross_ma_25':'El precio cruzó la banda central y cruzarla nuevamente en sentido contrario a la señal de entrada',
        'end':'La operación sigue abierta',
        'inverse_entry':'Señal con posición contraria',
        'macd_cross':'El MACD cruzó la línea de señal en sentido contrario al de la señal de entrada',
        'reverse_intersection':'MA6 cruzó MA70 en dirección opuesta a la señal de entrada',
        'rsi_cross':'El RSI cruzó la línea de 50 y la cruza otra vez en sentido contrario',
        'sar_trend_change':'Cambio de tendencia del SAR Parabólico',
        'stochastic_cross':'El Oscilador cruzó la línea de 50  y la cruza otra vez en sentido contrario',
        'stoploss':'Stop Loss. Salida tras perder el 2% del punto extremo'
    },
    unlockWithPremium:'Desbloquea datos fundamentales con Premium, obtén una suscripción',
    gross_profit:'Beneficio Bruto',
    revenue:'Ingresos',
    operating_income:'Ingresos de Explotación',
    year_change:'Evolución Ult. Año',
    disclaimer:'Descargo de responsabilidad: Dedicamos el mayor de nuestros esfuerzos para asegurarnos que los datos que proporcionamos sean relevantes, precisos y confiables. Pero las fuentes que utilizamos son de terceros, por lo que no podemos garantizar su confiabilidad y recomendamos encarecidamente verificar la información.',
    pl:{
        title:'P&L',
        'Total Revenue': 'Ingresos totales',
        'Cost Of Revenue':'Coste de los ingresos',
        'Gross Profit':'Beneficio Bruto',
        'Operating Expense':'Gastos de explotación',
        'Operating Income':'Ingresos de explotación',
        'EBITDA':'EBITDA',
        'Interest Expense':'Gastos financieros',
        'Net Income':'Beneficio Neto',
    },
    bs:{
        title:'Balance',
        'Current Assets':'Activos corrientes',
        'Cash And Cash Equivalents':'Efectivo y equivalentes de efectivo',
        'Accounts Receivable':'Cuentas a cobrar',
        'Inventory':'Existencias',
        'Total Non Current Assets':'Total activos no corrientes',
        'Total Assets':'Total Activo',
        'Current Liabilities':'Pasivo corriente',
        'Accounts Payable':'Cuentas a pagar',
        'Total Non Current Liabilities Net Minority Interest':'Total pasivo no corriente',
        'Total Liabilities Net Minority Interest':'Total Pasivo',
        'Capital Stock':'Capital Social',
        'Retained Earnings':'Ganancias acumuladas',  
        'Total Equity Gross Minority Interest':'Total Fondos Propios',
    },
    cf:{
        title:'Flujo de Caja',
        'Net Income':'Ingresos netos',
        'Operating Cash Flow':'Flujo de caja de explotación',
        'Investing Cash Flow':'Flujo de caja de inversión',
        'Financing Cash Flow':'Flujo de caja de financiación',
        'Repayment Of Debt':'Reembolso de la deuda',
        'Depreciation And Amortization':'D&A',
        'Issuance Of Debt':'Emisión de deuda',
        'Capital Expenditure':'Gastos de capital',
        'Cash Dividends Paid':'Dividendos pagados en efectivo',
        'Free Cash Flow':'Flujo de caja libre',
    }
},
header : {
    ranking:'BackTesting',
    todaysignals:'Today Signals',
    wiki:'Wiki',
    aboutus:'Sobre Nosotros'
},
footer:{
    description:'Nuestro objetivo es proveer información precisa y relevante mientras nos divertimos.',
    followus:'Descubre tu oportunidad de mercado',
    followus2:'Síguenos',
    aboutus:'Más sobre nosotros'
},
offcanvas:{
    filter:'Filtro',
    filter_button:'Filtrar',
    filter_select_all:'Seleccionar Todo',
    filter_getWithPremium:'Desbloquea con suscrición Premium',
    filter_getWithPro:'Desbloquea con suscrición Pro',
    settings:{
        settings:'Ajustes',
        candlestick: 'Color gráfico de velas',
        candlestick_settingsBlackWhite: 'Blanco y Negro',
        candlestick_settingsRedGreen: 'Rojo y Verde',
        theme: 'Tema',
        theme_dark:'Oscuro',
        theme_light:'Claro'
    },
    profile:{
        profile:'Perfil',
        name:'Nombre',
        email:'Email',
        logout:'Cerrar Sesión',
        manage_subscription: 'Gestiona tu suscripción',
        active_subscription:'Suscripción',
        get_subscription:'Obtén una suscripción',
        free:'Free',
        premium:'Premium',
        pro:'Pro',
    }
},
landing:{
    login:'Entra', 
    title:'Invierte inteligente ',
    title2:'con AtomicStonks',
    subtitle6:'Deja de perder el tiempo ',
    subtitle61:'analizando innumerables gráficos esperando encontrar la oportunidad de inversión perfecta. Nuestra herramienta calcula automáticamente las señales, ',
    subtitle62: 'tú eliges ',
    subtitle63: 'y profundizas en tu análisis.',
    subtitle1:'Calculamos las principales estrategias de análisis técnico para más de 1700 empresas cada día, creando un feed diario con las entradas a largo y a corto.',
    subtitle3:'Deja de perder el tiempo',
    subtitle31:' revisando miles de gráficos.',
	subtitle4:'Céntrate en elegir',
    subtitle41:' la oportunidad perfecta.',
	subtitle5:'Profundiza tu análisis',
    subtitle51:' con nuestras herramientas.',
    howitworks1:'AtomicStonks calcula las señales, ',
    howitworks2:'tú eliges',
    signalExpIntro1:'La sección ',
    signalExpIntro2:' muestra las señales generadas hoy, para que puedas concentrarte en elegir la correcta.',
    signalExpIntro3:'Esta es una señal a ',
    signalExpIntro4:' para la estrategia ',
    signalExpIntro5:' indicando que podría ser buena idea comprar Disney.',
    signalExp1:'Entiende y estudia cada empresa fácilmente para ver si es adecuada para ti.',
    signalExp1_1:'Accede a la información de la compañía con ',
    signalExp2:'y al gráfico con ',
    signalExp3:'Completa tu análisis en TradingView usando ',
    signalExp4:'Así de simple.',
    backTestingTitle1:'Accede a toda la información ',
    backTestingTitle2:'¿Funcionan las señales?',
    backTesting1:'',
    backTesting2:' muestra cómo se han comportado las señales generadas hace días al seguir la estrategia.',
    signalButton:'¡Únete!',
    features1:'+ 1700 Empresas',
    features11:'Cada día comprobamos si las estrategias se cumplen para más de 1700 empresas de todo el mundo, proporcionándote las señales.',
    features2:'Transparencia, nuestra prioridad',
    features22:'La ',
    features23:' contiene toda la información sobre los indicadores y estrategias que utilizamos, para que cualquiera pueda revisar nuestra información.',
    features3:'30 índices de 5 continentes',
    features33:'Analizamos 30 índices de 28 países para obtener información sobre el mercado global y la economía. Procesamos los principales índices como S&P 500, NASDAQ 100, DAX 30, CAC 40, IBEX 35, y Nikkei 225, entre muchos otros.',
    video:'https://atomicstonks-public.s3.eu-north-1.amazonaws.com/landing/landingES.mp4'
},
subscribeOnScreen:'¡Suscríbete!',
alertTopBar :{
    signalsGenerated:' señales generadas hoy ',
    subscribe:'suscríbete',
    toUnlock:' para desbloquearlas.',
},
pricing:{
    promo_code:'Usa el código promocional ATOMIC50 para tener un 50% de descuento el primer mes. Disponible por tiempo limitado.',
    button_monthly:'Mensual',
    button_yearly:'Anual',
    title:'¡Nuestros planes ',
    title2:'para ti!',
    subtitle:'Por tiempo limitado, disfruta del primer mes de AtomicStonks Premium y Pro ',
    subtitle2:'con un 50 % de descuento usando el código ATOMIC50',
    itsFree:"Es gratis!",
    thisIncludes:'Incluye:',
    free_title:'Free',
    free_button:'Entra',
    row1:'9 índices globales',
    row2:'+500 empresas analizadas todos los días',
    row3:'4 estrategias:',
    row3_1: 'Intersección de medias móviles',
    row3_2:'Parabolic SAR + MACD',
    row3_3:'Bollinger Bands + RSI',
    row3_4:'Stochastic Oscillator + RSI',
    row4:'Backtesting de las señales generadas 7, 30, 90 y 180 días antes',
    premium_title:'Premium',
    premium_badge:'Más Popular',
    premium_row1:'21 índices globales',
    premium_row2:'+1450 empresas analizadas todos los días',
    premium_row3:'5 estrategias:',
    premium_row3_1:'Intersección de medias móviles',
    premium_row3_2:'Parabolic SAR + MACD',
    premium_row3_3:'Bollinger Bands + RSI',
    premium_row3_4:'Stochastic Oscillator + RSI',
    premium_row3_5:'MACD + EMA',
    premium_row4:'Backtesting de las señales generadas 7, 30, 90 y 180 días antes',
    premium_row5:'Datos financieros y metadatos de la empresa',
    premium_row6:'TradingView toggle (si existe)',
    pro_title:'Pro',
    pro_row1:'30 índices globales',
    pro_row2:'+1700 empresas analizadas todos los días',
    pro_row3:'5 estrategias',
    pro_row3_1:'Intersección de medias móviles',
    prom_row3_2:'Parabolic SAR + MACD',
    pro_row3_3:'Bollinger Bands + RSI',
    pro_row3_4:'Stochastic Oscillator + RSI',
    pro_row3_5:'MACD + EMA',
    pro_row4:'Backtesting de las señales generadas 7, 30, 90 y 180 días antes',
    pro_row5:'Datos financieros y metadatos de la empresa',
    pro_row6:'TradingView toggle (si existe)',
    subscribe:'Suscríbete',
    saving1:'Te ahorras ',
    saving2:' al año',
    days_free:'Prueba 15 días gratis',
    region:'Región',
    country:'País',
    index:'Índice'
},
ranking:{
    topWinners: 'Top Ganadores',
    topLosers: 'Top Perdedores',
    carousel1: 'Top ',
    carousel2Winners: ' Ganadores',
    carousel2Losers: ' Perdedores',
    carouselNoLosers: 'No hay Perdedores en esta categoría',
    carouselNoWinners: 'No hay Ganadores en esta categoría',
},
aboutus:{
    title:"Sobre Nosotros",
    //intro:"Somos dos amigos de la universidad. Nos conocimos en la Universitat Politècnica de València, donde ambos estudiamos Ingeniería en Telecomunicaciones y Administración y Dirección de Empresas. Al terminar nuestros estudios, tuvimos la suerte de mudarnos a Barcelona y ser compañeros de piso. Hemos estado construyendo este proyecto mientras hacemos malabares con la vida personal, los estudios y el trabajo.",
    intro:"El equipo de AtomicStonks.",
    nameMarc:"Marc Nieto Aliques",
    nameMartin:"Martín Guimerà Castell",
    nameEstefania:"Estefanía Galvan",
    infoMarc:"¡Hola! Soy Marc, cofundador de AtomicStonks. Soy un apasionado de los datos, la programación y levantar hierro. Mi principal objetivo es avanzar constantemente, crear y pasarlo bien por el camino. He trabajado como investigador y llevo los últimos tres años como Data Analyst en un banco.",
    infoMartin:" Hey! Soy Martín, cofundador de AtomicStonks. Siempre me ha fascinado todo lo relacionado con los datos y el análisis. Durante los últimos tres años, he estado trabajando con una startup de rápido crecimiento y he pasado el último año y medio en el equipo de Analytics.",
    infoEstefania:"¡Hola! Soy Estefanía, cofundadora de AtomicStonks. Durante los últimos tres años, he estado trabajando en el espacio Fintech como Gerente de Marketing Digital tanto para empresas emergentes como para empresas de rápido crecimiento. Me apasiona impulsar mi creatividad a través de todo tipo de creaciones y construir marcas.",
    outtro:"Como puedes ver, amamos lo que hacemos y, con suerte, nos producirá un poco de dinero mientras ayudamos a geente a tomar mejores decisiones financieras.",
    contactus1:"No dudes en enviarnos un DM a cualquiera de nosotros o enviar un correo electrónico a ",
    contactus2:". Agradecemos cualquier comentario que nos haga mejorar y crear algo realmente útil.",
    linkedin:"Mira mi Linkedin"
},
filterGraph:{
    generated1:"Seleccionadas",
    generated2: "señales de",
    generated3: "generadas hoy",
    nodata: "Selección no válida",
},
singInModal:{
    singin:"Inicia sesión para continuar, ya casi lo tienes",
    policy1: "Al iniciar sesión aceptas nuestra ",
    privacy_policy:"política de privacidad",
    policy2:" y nuestros ",
    terms_conditions:"términos y condiciones."
},
data:{
    day_period:{
        "30 days":"30 días atrás",
        "90 days":"90 días atrás",
        "180 days":"180 días atrás",
        "7 days":"7 días atrás",
    },
    strategy:{
        bollinger_rsi: 'Bollinger + RSI',
        moving_average_intersection: 'Intersección SMA',
        macd_ema:'EMA + MACD',
        sar_macd:'SAR + MACD',
        stochastic_rsi: 'Oscilador Estocástico + RSI'
    },
    position:{
        short:'Corto',
        long:'Largo'
    },
    country:{
        ARG:"Argentina",
        AUS:"Australia",
        BEL:"Bélgica",
        BRA:"Brasil",
        CAN:"Canadá",
        CHE:"Suiza",
        CHL:"Chile",
        DEU:"Alemania",
        DNK:"Dinamarca",
        ESP:"España",
        FIN:"Finlandia",
        FRA:"Francia",
        GBR:"Reino Unido",
        HKG:"Hong kong",
        IND:"India",
        IRL:"Irlanda",
        ITA:"Italia",
        JPN:"Japón",
        KOR:"Corea del Sur",
        MEX:"México",
        NLD:"Países Bajos",
        NOR:"Noruega",
        PRT:"Portugal",
        SGP:"Singapur",
        SWE:"Suecia",
        USA:"Estados Unidos",
        ZAF:"Sudáfrica"
    },
    sector: {
        basic_materials:"Materiales Básicos",
        communication_services:"Servicios de Comunicación",
        consumer_cyclical:"Bienes de Consumo Cíclicos",
        consumer_defensive:"Bienes de Consumo Básicos",
        energy:"Energía",
        financial_services:"Servicios Financieros",
        healthcare:"Salud",
        industrial_goods:"Bienes Industriales",
        industrials:"Industriales",
        real_estate:"Inmobiliaria",
        technology:"Tecnología",
        utilities:"Servicios Públicos"
    },
    industry:{
        advertising_agencies:"Agencias de Publicidad",
        aerospace_defense:"Aeroespacial",
        agricultural_inputs:"Agricultura",
        airlinesairports_air_services:"Aerolíneas, Aeropuertos y Servicios Aéreos",
        asset_management:"Gestión de Activos",
        auto:"Automovilístico",
        banks:"Bancos",
        beverages_alcoholic:"Bebidas Alcohólicas",
        beverages_nonalcoholic:"Bebidas No Alcohólicas",
        biotechnology:"Biotecnología",
        building_materials:"Materiales de Construcción",
        building_products_equipment:"Construcción de Productos y Equipos",
        business_equipment_supplies:"Equipos y Suministros Comerciales",
        capital_markets:"Mercados de Capitales",
        chemicals:"Químicos",
        clothing_footwear_accessories:"Ropa, Calzado y Accesorios",
        communication_equipment:"Equipos de Comunicacion",
        computer_hardware:"Hardware de La Computadora",
        conglomerates:"Conglomerados",
        consumer_electronics:"Electrónica de Consumo",
        credit_services:"Servicios de Crédito",
        data_analytics:"Análisis de Datos",
        department_stores:"Grandes Almacenes",
        diagnostics_research:"Diagnóstico e Investigación",
        discount_stores:"Tiendas de descuento",
        drugs:"Fármacos",
        education_training_services:"Servicios de Educación y Capacitación",
        electrical_equipment_parts:"Equipo y Piezas Eléctricos",
        electronic_components:"Componentes Electrónicos",
        engineering_construction:"Ingeniería y Construcción",
        entertainment:"Entretenimiento",
        farm_products:"Productos Agrícolas",
        financial_conglomerates:"Conglomerados Financieros",
        financial_data_stock_exchanges:"Datos Financieros e Intercambios de Valores",
        food_food_distribution:"Distribución de Alimentos y Alimentos",
        food_food_distribution_confectioners:"Alimentos, Distribución de Alimentos y Confitores",
        furnishings_fixtures_appliances:"Muebles, Accesorios y Electrodomésticos",
        gambling_casinos_resorts:"Juego, Casinos y Resorts",
        gold_copper:"Oro y Cobre",
        grocery_stores:"Tiendas de Comestibles",
        health_information_services:"Servicios de Información Sobre La Salud",
        healthcare_plans:"Planes de Atención Médica",
        hotels_lodging:"Hoteles y Alojamiento",
        household_personal_products:"Productos Para El Hogar y El Hogar",
        industrial_distribution:"Distribución Industrial",
        information_technology_services:"Servicios de Tecnología de La Información",
        infrastructure_operations:"Operaciones de Infraestructura",
        insurance:"Seguros",
        integrated_freight_logistics:"Flete Integrado y Logística",
        internet_content_information:"Contenido e Información de Internet",
        internet_retail:"Minorista de Internet",
        leisure:"Ocio",
        luxury_goods:"Artículos de Lujo",
        machinery__industrial_construction_farm_speciality:"Maquinaria: Industrial, Construcción, Granja y Especialidad",
        marine_shipping:"Envío Marino",
        medical_care_facilities:"Instalaciones de Atención Médica",
        medical_devices:"Dispositivos Médicos",
        medical_distribution:"Distribución Médica",
        medical_instruments_supplies:"Instrumentos y Suministros Médicos",
        mining:"Minería",
        oil_gas:"Gas de Petróleo",
        packaging_containers:"Embalaje y Contenedores",
        paper_wood_production:"Producción de Papel y Madera",
        personal_services:"Servicios Personales",
        pharmaceutical_retailers:"Minoristas Farmacéuticos",
        publishing:"Editoriales",
        railroads:"Ferrocarriles",
        real_estate:"Bienes Raíces",
        reit_B2B:"Reit B2B",
        rental_leasing_services:"Servicios de Alquiler y Arrendamiento",
        residential_construction:"Construcción Residencial",
        restaurants:"Restaurantes",
        retail:"Minorista",
        scientific_technical_instruments:"Instrumentos Científicos y Técnicos",
        security_protection_services:"Servicios de Seguridad y Protección",
        semiconductors:"Semiconductores",
        software:"Software",
        solar:"Solar",
        specialty_business_services:"Servicios Comerciales Especializados",
        staffing_employment_services:"Servicios de Personal y Empleo",
        steel_aluminum:"Acero y Aluminio",
        telecom_services:"Servicios de Telecomunicaciones",
        textile_manufacturing:"Fabricación Textil",
        thermal_coal:"Carbón Térmico",
        tobacco:"Tabaco",
        tools_accessories:"Herramientas y Accesorios",
        travel_services:"Servicios de Viaje",
        trucking:"Transporte y Camiones",
        uranium:"Uranio",
        utilities_diversified:"Servicios Públicos Diversificados",
        utilities_independent_power_producers:"Productores de Energía Independientes de Servicios Públicos",
        utilities_regulated_energy:"Servicios Públicos Energía Regulada",
        utilities_regulated_water:"Agua Regulada de Servicios Públicos",
        utilities_renewable:"Servicios Públicos Renovables",
        waste_management_pollution_treatment_controls:"Controles de Gestión de Residuos, Contaminación y Tratamiento"
    },
    indexes:{
        AEX25:"AEX 25",
        ASX50:"ASX 50",
        BEL20:"BEL 20",
        BMVIPC:"BMV IPC",
        CAC40:"CAC 40",
        CLXIPSA:"CLX IPSA",
        DAX40:"DAX 40",
        DOWJONES:"Dow Jones",
        EUROSTOXX50:"EURO STOXX 50",
        FTSE100:"FTSE 100",
        FTSEMIB:"FTSE MIB",
        HSI50:"HSI 50",
        IBEX35:"IBEX 35",
        IBOVESPA:"IBOVESPA",
        ISEQ20:"ISEQ 20",
        KOSPI:"KOSPI",
        MERVAL:"MERVAL",
        NASDAQ100:"NASDAQ 100",
        NIFTY50:"NIFTY 50",
        NIKKEI225:"NIKKEI 225",
        OBXO25:"OBX Oslo 25",
        OMXC25:"OMX Copenhage 25",
        OMXH25:"OMX Helsinki 25",
        OMXS30:"OMX Stockholm 30",
        PSI20:"PSI 20",
        SA40:"SA 40",
        SMI:"SMI ",
        SP500:"S&P 500",
        STI:"STI ",
        TSX60:"TSX 60"
    },
    exchange: {
        AMS:"Euronext Amsterdam",
        ASX:"Australian Securities Exchange",
        BCBA:"Buenos Aires Stock Exchange",
        BIT:"Borsa Italiana",
        BME:"Spanish Stock Exchanges and Markets",
        BMV:"Bolsa Mexicana de Valores",
        BVMF:"B3 – Brasil, Bolsa, Balcão",
        CPH:"Nasdaq Copenhagen",
        DWX:"SIX Swiss Exchange",
        EBR:"Euronext Brussels",
        ELI:"Euronext Lisbon",
        EPA:"Euronext Paris",
        ETR:"Frankfurt Stock Exchange",
        HEL:"Nasdaq Helsinki",
        HKG:"Stock Exchange of Hong Kong",
        ISE:"Euronext Dublin",
        JSA:"Johannesburg Stock Exchange",
        KRX:"Korea Exchange",
        LSE:"London Stock Exchange",
        NASDAQ:"Nasdaq",
        NSE:"National Stock Exchange of India",
        NYSE:"New York Stock Exchange",
        OSL:"Oslo Stock Exchange",
        SGX:"Singapore Exchange",
        SSE:"Santiago Stock Exchange",
        STO:"Nasdaq Stockholm",
        TSE:"Toronto Stock Exchange",
        TYO:"Tokyo Stock Exchange"
    },
    continent:{
        EEE:"Europa",
        EU:"Unión Europea",
        UUU:"Oceanía",
        SRR:"Sudamérica",
        NNN:"Norteamérica",
        FFF:"África",
        ABB:"Asia"
    }
},
wiki:wikiEs,
wikiHeaders:wikiHeadersEs,
};